import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Layout } from "../components/layout"
import { Hero, Container, SectionHeader } from "@revfluence/aspire-www-ui"
import { PageSection } from "../components/page-section"
import SEO from "../components/seo"

const SectionContentDiv = styled.div`
  margin-bottom: 32px;
`
const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default function Timeline({ data }) {
  return (
    <Layout>
      <SEO
        title="Timing is everything. Are you prepared for the holidays?"
        description="Wondering how many campaigns to run, when you need to negotiate terms, and how to approach delayed shipping items from COVID-19? We've got your back."
        url="holiday.aspireiq.com/timeline"
      />
      <Hero size="sm">{null}</Hero>
      <Container>
        <PageSection>
          <SectionHeader
            Cta={() => null}
            headline="Timing is everything"
            Copy={() => (
              <div>
                <SectionContentDiv>
                  Wondering how many campaigns to run, when you need to
                  negotiate terms, and how to approach delayed shipping items
                  from COVID-19? We've got your back.
                </SectionContentDiv>
                <SectionContentDiv>
                  Our holiday timeline covers the step-by-step around launching
                  Black Friday campaigns, so you don't miss a beat.
                </SectionContentDiv>
              </div>
            )}
            position="center"
          />
        </PageSection>
        <PageSection>
          <ImageContainer>
            <Img
              style={{ width: "100%" }}
              fluid={data.timeline.childImageSharp.fluid}
            />
          </ImageContainer>
        </PageSection>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    timeline: file(relativePath: { regex: "/calendar-image/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
